.login-wrapper{
 display: flex;
 align-items: center;
 height: 100vh;

 .login-logo{
  background-color: #f9f9f9;
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
 }
 .login-form{
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 30%;
  padding: 0 64px;
 }
}
