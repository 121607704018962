.delete-unit-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__title {
    align-items: flex-start;

    span {
      font-size: 1.125em;
    }
  }

  &__action {
    align-self: flex-start;
  }

  &__content {
    padding-top:  0.75em !important;
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 75%;
  }

  &__warning {
    margin-top: 0.5em;
    font-size: 0.875em;
    color: #6c737f;
  }
}

