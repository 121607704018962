.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75em 1.5em;
  background-color: #fff;
  border-bottom: 1px solid var(--border-color);

  &__logo-container {
    flex: 1;
    cursor: pointer;
  }

  &__header-logo {
    height: 3.125em;
    width: auto;
  }

  &__button-container {
    flex-shrink: 0;
  }

}


