.basic-unit-details-header{
  display: flex;
  flex-direction: column;
  width: 100%;

  &__navigation {
    margin-bottom: 2em;
    padding: 0.5em;
    width: fit-content;
    cursor: pointer;

    &:hover {
      background-color:var(--button-hover-color);
      border-radius:  0.75em;
    }
  }
  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2em;
  }
  &__thumbnail {
    width:  5em;
    height: 4em;
  }
  &__edit-action {
    display: flex;
    align-items: center;
    padding: 0.5em;
    gap: 0.5em;
    cursor: pointer;

    &:hover {
      border-radius: 0.75em;
      background-color: var(--button-hover-color);
    }
  }
  &__edit-icon {
    font-size: 0.875em;
    font-weight: 600;
  }
}
