.card-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.75em 1.5em;
  gap: 1em;
  &__title-column {
    display: flex;
    flex-direction: column;
    width: 25%;
  }

  &__content-column {
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    max-width: 50%;
    white-space: normal;
  }
  &__link{
    color: var( --primary-main-color);
    text-decoration: underline;
  }
}
