.account-details-container{
  padding: 1em 0.5em 1em 1em;
  &__info{
    word-wrap: break-word;
  }
  &__change-password {
    cursor: pointer;
    &:hover{
      color: var(--primary-main-color);
    }
    &--icon{
      font-size:1.5em ;
    }
  }
  &__logout-box {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-main-color);
    font-weight: 600;
    font-size: 0.8em;
  }
}




