.publication-form-container {
  max-height: 34em;
  overflow-y: auto;
  padding-right: 0.3em;
  padding-bottom: 0.3em;

  &__date {
    flex: 1;
    height: 3.5em;
  }

  &__checkbox-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    color:#6C737F;
    border: 1px solid var(  --custom-border-color);
    padding: 5px 100px 5px 10px;
  }
}
