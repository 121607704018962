@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap");

html {
  font-size: 16px;
}

body {
  font-family: "Inter", serif;
  max-width: 100vw;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}
