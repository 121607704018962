.publication-form-modal{
  &__paper-buttons{
    border-radius: 0.5em;
    border: 1px solid var(--custom-border-color);
    padding: 1em 1.875em;
    cursor: pointer;

    &--text{
      color: var(--drop-zone-format-title);
    }
    &.active {
      border: 1px solid var(--primary-main-color);
      color:var(--primary-dark-color);
    }
  }

}
