
.uploader-zone {
  &__subheader-text {
    margin: 1em 0 1em 0;
  }

  &__drop-zone {
    display: flex;
    align-items: center;
    gap: 1em;
    padding: 3em;
    border: 0.1em dashed var(--drop-zone-border);
    border-radius: 1em;
    cursor: pointer;
  }

  &__icon-container {
    background-color: var(--drop-zone-icon-background);
    border-radius: 2em;
    padding: 0.6em 0.9em;
  }

  &__format-title {
    color: var(--drop-zone-format-title);
  }

  &__upload-text {
    cursor: pointer;
    font-size: 1em;
    font-style: normal;
    font-weight: 600;

    &--underlined {
      text-decoration: underline;
    }
  }

  &__image-container {
    position: relative;
    overflow-x: hidden;

    .uploaded-image {
      max-width: 100%;
      height: auto;
      border-radius: 5px;
    }


    .remove-image-button {
      position: absolute;
      top: 0.3125em;
      right: 0.3125em;
      background-color: var(--remove-image-button-color);
      color: var(--common-light-color);
      z-index: 2;
      padding: 0.1em;
      opacity: 0;
      transition: opacity 0.3s;;
    }


    &:hover {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--image-overlay);
        z-index: 1;
      }

      .remove-image-button {
        opacity: 1;
      }
    }
  }

  @media (max-width: 767px) {
    &__drop-zone {
      padding: 1em 2em;
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    &__drop-zone {
      padding: 1em 2em;
    }
  }
}
