:root{
  --common-light-color:#FFFFFF;
  --common-black-color:#000000;

  --border-color:#F2F4F7;
  --custom-border-color:#D2D6DB;
  --scroll-background-color:#f1f1f1;
  --scroll-color:#8F8F93;
  --scroll-hover-color:#555;

  --drop-zone-border:#dad6de;
  --drop-zone-icon-background:#E5E7EB;
  --drop-zone-format-title: #6C737F;

  --primary-lightest-color:#FAFDFF;
  --primary-light-color:#ECF4FA;
  --primary-main-color:#3178B3;
  --primary-dark-color:#245D8B;
  --primary-dakrest-color:#193E5D;

  --secondary-lightest-color:#F9F9F9;
  --secondary-light-color:#F3F4F6;
  --secondary-main-color:#384250;
  --secondary-dark-color:#1C2536;
  --secondary-dakrest-color:#111927;

  --error-lightest-color: #FEF3F2;
  --error-light-color: #FEE4E2;
  --error-main-color: #F04438;
  --error-dark-color: #B42318;
  --error-darkest-color: #7A271A;

  --green-lightest-color: #F6FEF9;
  --green-light-color: #EDFCF2;
  --green-main-color: #16B364;
  --green-dark-color: #087443;
  --green-darkest-color: #084C2E;

  --indigo-lightest-color: #F5F7FF;
  --indigo-light-color: #EBEEFE;
  --indigo-main-color: #6366F1;
  --indigo-dark-color: #4338CA;
  --indigo-darkest-color: #312E81;

  --purple-lightest-color: #F9F5FF;
  --purple-light-color: #F4EBFF;
  --purple-main-color: #9E77ED;
  --purple-dark-color: #6941C6;
  --purple-darkest-color: #42307D;

  --success-lightest-color: #F0FDF9;
  --success-light-color: #3FC79A;
  --success-main-color: #10B981;
  --success-dark-color: #0B815A;
  --success-darkest-color: #134E48;

  --info-lightest-color: #ECFDFF;
  --info-light-color: #CFF9FE;
  --info-main-color: #06AED4;
  --info-dark-color: #0E7090;
  --info-darkest-color: #164C63;

  --warning-lightest-color: #FFFAEB;
  --warning-light-color: #FEF0C7;
  --warning-main-color: #F79009;
  --warning-dark-color: #B54708;
  --warning-darkest-color: #7A2E0E;

  --table-hover-color:rgba(17, 25, 39, 0.04);
  --loader-background-color:rgba(255, 255, 255, 0.7);
  --remove-image-button-color: rgba(37, 35, 35, 0.66);
  --image-overlay:rgba(255, 255, 255, 0.3);
  --button-hover-color: #f8f9fa;

}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scroll-color);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color:  var(--scroll-hover-color);
}

::-webkit-scrollbar-track {
  background-color: var(--scroll-background-color) ;
}

.page-table{
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.latest-news{
  width: 85%;
}

.MuiDataGrid-root .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row {
  cursor: pointer;
}


