.basic-table {
  &__row {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }

  tr:hover {
    background: var( --table-hover-color);
    cursor: pointer;
  }

  &__header {

    th.MuiTableCell-root {
      background-color:var( --table-hover-color);
      cursor: default;
    }
  }

  &__loader {
    margin-top: 1em;
    position: absolute;
    right: 50%;
    top: 50%;
  }

  .MuiPaper-root {
    padding: 0.875em;
    border-radius: 0.75em;
  }
}

@media (max-width: 870px) {
  .basic-table {
    &__row {
      text-align: center;
    }
  }
}

.nonclickable-style:hover {
  background: red;
  cursor: default !important;
}
