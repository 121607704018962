.digital-object-card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  word-break: break-word;
  &__image-container {
    display: flex;
    align-items: flex-start;

    img {
      height: 13.75em;
      width: 10em;
    }
  }
}
