.filter-section {
  padding: 8px 24px;
  align-items: center;
  display: flex;
  gap: 5px;

  .filter-search {
    width: 60%;
  }

  &__sort {
    width: 30%;
  }

  .MuiSelect-select {
    padding: 16px 8px;
  }

  .MuiButton-root {
    line-height: normal;
    padding: 10px 16px;
  }

  &__search-button {
    font-size:15px !important;
    padding: 16px 45px !important;
  }
}

@media only screen and (max-width: 768px) {
  .filter-section {
    &__sort,
    &__filter {
      width: 100%;
    }
  }
}
