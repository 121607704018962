.table-row {
  &__featured-image-container {
    height: 170px;
    padding-right: 25px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
}
