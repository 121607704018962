.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--loader-background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  &__container {
    position: relative;
  }
}
