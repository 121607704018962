.latest-news-content {
  width: 33.33%;
  cursor: pointer;
  padding: 20px;

  &:hover {
    background-color: var( --secondary-light-color);

    img {
      transform: scale(1.1);
    }
  }

  &__description {
    padding-top: 12px;

    p {
      margin-bottom: 0.5em;
      margin-top: 0.5em;
    }
  }

  &__date {
    color:var(--primary-main-color);
    font-size: 14px;
    font-weight: 600;
  }

  &__image-wrapper {
    height: 254px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s;
  }
}
