.specimen-info-component {
  padding: 16px;
  border: 1px solid #000;
  border-radius: 8px;
  margin: 0 auto;
  background-color: #fafafa;

  &__row {
    display: flex;
    padding: 8px 0;

    &:not(:last-child) {
      border-bottom: 1px solid #F2F4F7;
    }
  }

  &__label {
    font-size: 14px;
    color: #000;
    width: 50%;
  }

  &__value {
    font-size: 14px;
    color: #757575;
    width: 50%;
  }
}
