.modal {
  padding:1em 1.5em;
  color: black;
  &__title {
    font-size: 1.125em;
    font-style: normal;
    font-weight: 700;
    padding: 0;
  }


  #alert-dialog-title {
    padding: 0;
  }

}

.action-button {
  margin-top: 0.5em;
  gap: 0.5em;
}

#description-dialog {
  padding-top: 0;
}
