.no-data-row {
  &__message {
    border: none;
    font-size: 1em;
    margin-bottom: 1em;
    &--text {
      margin-bottom: 1em;
      margin-top: 1em;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5em;
    }
  }
}
