.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__text-container{
    background-color:black;
    color: white;
    opacity: 0.8;
  }

  &__home-image-text {
    line-height: 1.8;
    font-size: 18px;
    color: white
  }

  &__header-img {
    height: 600px;
    width: 100%;
    object-fit: cover;
  }

  &__children {
    padding: 4em 4em 4em 4em;

    &--title {
      padding-bottom: 3em;
      .no-title & {
        padding-bottom: 0;
      }
    }

  }

  .layout__text-container {
    background-color: white !important;
    color: black !important;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.4);
    opacity: 95%;
  }

  .layout__text-container h1,
  .layout__text-container h3,
  .layout__text-container p {
    color: black !important;
  }
}
