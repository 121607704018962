.change-password {
  background: var(--secondary-light-color);
  width: 800px;
  border-radius: 10px;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__title {
    padding-bottom: 2em;
    font-size: 1.125em;
    font-weight: 700;
  }

  &__button-container {
    margin-top: 1em;
    justify-content: end;
  }

}
