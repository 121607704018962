.custom-avatar {
  width: 2.5em;
  height: 2.5em;
  cursor: pointer;

  &--small {
    width: 1.5em;
    height:1.5em;
  }

  &--large {
    width: 4em;
    height: 4em;
  }
}
