.footer{
  border-top: 0.0625em solid var(--border-color);
  padding: 0.75em 1.5em;
  &__image{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
