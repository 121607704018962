.add-or-update-specimen-info{
  &__checkbox-container{
    display: flex;
    justify-content: space-evenly;
  }
  &__input-container{
    border: 1px solid #000;
    border-radius: 8px;
  }
}
